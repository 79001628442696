import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";

export default function CaseStudiesIndex({ excerpts, excerptClasses }) {
    let class_names = 'relative block sm:p-5 text-center no-underline w-full image-excerpt';

    if (excerptClasses) class_names = class_names + ' ' + excerptClasses;

    return (
        <div className="grid md:grid-cols-2 md:gap-5 xl:grid-cols-3 excerpt-grid">
            { excerpts.map(( excerpt, index) => {

                const renderSummary = () => {
                    if (excerpt.summary) {
                        return (
                            <div className="flex-col justify-center absolute top-0 left-0 bg-primary bg-opacity-85 w-full h-full summary-container">
                                <p className={"px-5 text-sm sm:text-base text-white summary"}>{excerpt.summary}</p>
                                <p className="text-sm sm:text-base text-white underline uppercase read-more">Read More</p>
                            </div>
                        )
                    }
                };

                return (
                    <article className={"mb-5 w-full md:mb-0"} key={index}>
                        <Link to={excerpt.path} className={class_names}>
                            <div className="relative image-container">
                                <Image fluid={excerpt.image} sizes={{...excerpt.image, aspectRatio: 1 / 1}}/>

                                { renderSummary() }
                            </div>

                            <h2 className={"mt-4 text-xl md:text-2xl no-underline"}>{excerpt.title}</h2>
                        </Link>
                    </article>
                )
            }) }
        </div>
    )
};

import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PageTitle from "../components/page-title";
import SEO from "../components/seo";
import ExcerptGrid from "../components/excerpt-grid";

export default function CaseStudiesIndex({ data, location }) {
    const caseStudies = data.caseStudies.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        rObj.summary = obj.node.frontmatter.summary;
        rObj.image = obj.node.frontmatter.image.childImageSharp.fluid;
        return rObj
    });

    const masthead_slides = data.slides.edges.map(obj => {
        let rObj = {};
        rObj.image = obj.node.frontmatter.image.childImageSharp.fluid;
        return rObj
    });

    return (
        <Layout location={location} masthead={{display: true, slides: masthead_slides}}>
            <SEO title="Case Studies" />

            <PageTitle title="Case Studies" />

            <ExcerptGrid excerpts={caseStudies} excerptClasses="case-study"/>
        </Layout>
    )
};

export const CaseStudiesQuery = graphql`
    query {
        caseStudies: allMarkdownRemark(filter: {fileAbsolutePath: {glob: "**/content/case-studies/details/*/index.md*"}}, sort: {fields: frontmatter___order}) {
            edges {
                node {
                    frontmatter {
                        path
                        summary
                        title
                        image {
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                  ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
        slides: allMarkdownRemark(filter: { fileAbsolutePath: {
            glob: "**/content/case-studies/index-slides/*.md"
        }}, sort: {fields: fileAbsolutePath}) {
            edges {
                node {
                    frontmatter {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                  ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                        caption
                    }
                }
            }
        }
    }
`
